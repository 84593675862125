<template>
  <div class="scheme-contain">
    <div class="drug-box">
      <Form :label-width="110">
        <Row>
          <Col span="8" offset="7">
            <Form-item label="产品名称：">
              <Select filterable clearable placeholder="请选择" v-model="addForm.drugNameId">
                <Option v-for="item in drugList" :value="item.id" :key="item">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="领货时间：">
              <Date-picker type="date" placeholder="选择日期" @on-change="changeTime" :value="addForm.startTime"></Date-picker>
            </Form-item>
            <Form-item label="领货数量：">
              <Input placeholder="请输入领货数量..." type="text" v-model="addForm.num"></Input>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import linghuoService from '@/services/linghuoService';
export default {
	data() {
		return {
			drugList: [],
			addForm: {
				drugTypeId: '',
				startTime: '',
				num: '',
			},
			complianceData: [],
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},

	created() {
		linghuoService.indexServices().then((data) => {
			this.drugList = data.service_type;
		});
	},

	methods: {
		// 获取服药服从性列表
		changeTime(date) {
			this.addForm.startTime = date;
		},

		validateFormData() {
			if (!this.addForm.drugNameId) {
				this.$Message.warning('请选择产品名称');
				return false;
			} else if (!this.addForm.startTime) {
				this.$Message.warning('请选择时间');
				return false;
			} else if (this.addForm.num.length === 0) {
				this.$Message.warning('请输入领货数量');
				return false;
			} else {
				return true;
			}
		},

		handleSave() {
			var reqData = {};
			reqData = {
				member_id: this.memberId,
				drug_class: this.addForm.drugNameId,
				service_time: this.addForm.startTime,
				number: this.addForm.num,
			};
			if (this.validateFormData()) {
				linghuoService.addServices(reqData).then(() => {
					this.$router.go(-1);
				});
			}
		},

		handleBack() {
			this.$router.go(-1);
		},
	},
};
</script>
<style lang="css" scoped>
.drug-box {
  padding-top: 20px;
}
.drug-text {
  color: #999;
}
</style>
